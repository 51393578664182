import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationService } from '../navigation/navigation.service';
import { Observable, Subject, takeUntil } from 'rxjs';
import { selectUser, selectUserBalances } from '../../store/user/selectors/user.selector';
import { appState } from 'src/app';
import { Store } from '@ngrx/store';
import { LocalStorageService } from '../localStorage/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class GameLaunchService {
  unsubus$: Subject<boolean> = new Subject();
  user$: Observable<any> = this.store.select(selectUser);
  Balance$: Observable<any> = this.store.select(selectUserBalances);

  constructor(
    private http: HttpClient,
    private navigator: NavigationService,
    private store: Store<appState>,
    private storage: LocalStorageService
  ) {}

  launchGame(demo: '1' | '0', game: any, isMobile: boolean) {
    // const lang = this.helpers.getCookie('userlang');
    // 998 - evosw  / 983 - ezugi
    console.log(game);
    return this.http.post('/api/v2/games/launch', {
      gameId: game.softgamingId,
      currency: 'eur',
      demo: demo === '1' ? true : false,
      isMobile: isMobile,
    });
  }

  checkUserProfile(gameUrl: string) {
    this.user$
      .pipe(takeUntil(this.unsubus$))
      .subscribe((res: any) => {
        if (res.isAuth && res.loaded) {
          // if (Object.keys(res?.userInfo?.data?.loyalty?.ActiveBonuses || {}).length > 0) {
          //   const gameCategories = this.storage.getSessionStorage('preLoginGameCategories');
          //   const parsedGameCategories = gameCategories ? JSON.parse(gameCategories) : [];
          //   this.storage.removeSessionStorage('preLoginGameCategories');
          //   if (this.checkGameCategories(parsedGameCategories)) {
          //     this.navigator.navigateTo(`/`);
          //     return;
          //   }
          // }
          if (
            (res.userProfile?.user?.profile?.lastName === '' &&
              res.userProfile?.user?.profile?.firstName?.indexOf('srwelle') > -1) ||
            (res.userProfile?.user?.profile?.lastName?.indexOf('srwelle') > -1 &&
              res.userProfile?.user?.profile?.countryIso3 !== 'rus')
          ) {
            this.navigator.navigateTo(`/(aside:account/profile)`);
            return;
          }

          this.Balance$.pipe(takeUntil(this.unsubus$))
            .subscribe((balanceRes: any) => {
              //FREE ROIUNDS
              if (balanceRes?.isLoaded) {
                const activeBalance = balanceRes.balances.find((balance: any) => balance.type === 'ACTIVE')?.amount;

                if (activeBalance === 0) {
                  if (gameUrl.includes('1477472')) {
                    this.storage.setSessionStorage('preGameUrl', this.navigator.getCuretRoute());
                    this.navigator.navigateByOrder(gameUrl, `/(aside:cashier/deposit)`);
                  } else {
                    this.navigator.navigateTo(`/(aside:cashier/deposit)`);
                  }
                  return;
                }

                this.storage.setSessionStorage('preGameUrl', this.navigator.getCuretRoute());
                this.navigateToGame(gameUrl);
                return;
              }
            })
            .unsubscribe();
        } else {
          this.storage.setSessionStorage('preLoginGameUrl', gameUrl);
          this.storage.setSessionStorage('preGameUrl', this.navigator.getCuretRoute());
          this.navigator.navigateTo(`/auth/sign-in`);
        }
      })
      .unsubscribe();
  }
  navigateToGame(gameUrl: string) {
    this.storage.setSessionStorage('preGameUrl', this.navigator.getCuretRoute());
    this.navigator.navigateTo(gameUrl);
  }
  unsubscribe() {
    this.unsubus$.next(true);
    this.unsubus$.complete();
  }
  checkGameCategories(categories: any[]) {
    const forbiddenCategories = ['bonus_risk', 'progression_feature', 'savingsgames', 'bonusrestricted'];
    return categories.some((item: any) => forbiddenCategories.includes(item.slug));
  }
}
